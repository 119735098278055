import React, { useEffect, useState } from "react"
import { DataTable } from "../../../../components/Table/DataTable"
import { Drawer, notification, Spin } from "antd"

import DataTableColumnHeader from "../DataTableColumnHeader"
import { Container, Title } from "./styles"
import { sortByTime } from "../helper"
import { formatDateBrazil } from "../../../../utils/format-date-brazil"

import { IoMdCheckbox } from "react-icons/io";
import { useAuth } from "../../../../hooks/useAuth";
import api from "../../../../service/api";
import TituloTela from "../../../../components/TituloTela"

const detalhesApontamentoColumns = [
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Nº OP" />,
    title: "Nº OP",
    accessorKey: 'nr_ordem_producao',
    cell: ({ row }) => row.original.nr_ordem_producao,
    widthColumn: 50,
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Nº OS" />,
    title: "Nº OS",
    accessorKey: 'nr_ordem_servico',
    cell: ({ row }) => row.original.nr_ordem_servico,
    widthColumn: 50,
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Início" />,
    title: "Início",
    accessorKey: "dt_hr_inicio",
    cell: ({ row }) => {
      return formatDateBrazil(row.original.dt_hr_inicio)
    },
    widthColumn: 200,
    sortingFn: 'datetime'
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Fim" />,
    title: "Fim",
    accessorKey: "dt_hr_fim",
    cell: ({ row }) => {
      return formatDateBrazil(row.original.dt_hr_fim)
    },
    widthColumn: 200,
    sortingFn: 'datetime'
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Executado" />,
    title: "Executado",
    cell: ({ row }) => row.original.qtd_hr_executado,
    accessorKey: "qtd_hr_executado",
    widthColumn: 50,
    align: 'right',
    sortingFn: sortByTime
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Operador" />,
    title: "Operador",
    accessorKey: 'desc_operador',
    cell: ({ row }) => row.original.desc_operador,
    widthColumn: 200,
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Processo" />,
    title: "Processo",
    accessorKey: 'desc_processo',
    cell: ({ row }) => row.original.desc_processo,
    widthColumn: 200,
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Máquina" />,
    title: "Máquina",
    accessorKey: 'desc_maquina',
    cell: ({ row }) => row.original.desc_maquina,
    widthColumn: 200,
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Tipo Apontamento" />,
    title: "Tipo Apontamento",
    accessorKey: 'desc_tipo_apontamento',
    cell: ({ row }) => row.original.desc_tipo_apontamento,
    widthColumn: 150,
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Cancelado" />,
    title: "Cancelado",
    accessorKey: 'cancelado',
    cell: ({ row }) => row.original.cancelado === "S"
      ? <IoMdCheckbox size={16} color="#52c41a" />
      : "Não",
    widthColumn: 150,
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Finalizado" />,
    title: "Finalizado",
    accessorKey: 'finalizou_processo',
    cell: ({ row }) => row.original.finalizou_processo === "S"
      ? <IoMdCheckbox size={16} color="#52c41a" />
      : "Não",
    widthColumn: 150,
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Qtd. Produzida" />,
    title: "Qtd. Produzida",
    accessorKey: 'qtd_produziu',
    cell: ({ row }) => Number.parseInt(row.original.qtd_produziu),
    widthColumn: 150,
  },
  {
    header: ({ column }) => <DataTableColumnHeader column={column} title="Qtd. Perdida" />,
    title: "Qtd. Perdida",
    accessorKey: 'qtd_perdeu',
    cell: ({ row }) => Number.parseInt(row.original.qtd_perdeu),
    widthColumn: 150,
  },
]

export const DetalhesApontamento = ({
  produtoAcabado,
  isOpen,
  closeFn,
  timePeriod
}) => {
  
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { empresa } = useAuth();
  
  useEffect(() => {
    if(!isOpen){
      return;
    }

    async function getData() {
      try {
        setIsLoading(true);
  
        const { data } = await api.get(
          `/empresas/${empresa.id}/pcp/apontamento/produto-acabado/${produtoAcabado.id}/detalhes`,
          {
            params: {
              inicioPeriodo: new Date(timePeriod.from),
              finalPeriodo: new Date(timePeriod.to)
            }
          }
        )
  
        if (data.response.length === 0) {
          notification.open({
            message: 'Nenhum resultado encontrado',
            description: "Nenhum registro encontrado no período informado"
          });
        }
  
        setData(data.response);
      } catch (error) {
        notification.open({
          message: 'Erro!',
          description: "Ocorreu um erro ao realizar a busca"
        });
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, [empresa.id, isOpen, produtoAcabado, timePeriod])

  return (
    <Drawer
      placement="right"
      onClose={closeFn}
      visible={isOpen}
      destroyOnClose
      title={
        <TituloTela codigoTela="01.77.1478" nomeTela="Apontamentos por Produto Acabado - Detalhes" />
      }
      width={"95vw"}
    >
      <Container>
        <Title>
          <h3 className="operator">{produtoAcabado?.id}</h3>
          <h4 className="product">{produtoAcabado?.nome}</h4>
        </Title>
        <Spin spinning={isLoading} >
          <DataTable
            columns={detalhesApontamentoColumns}
            dataSource={data}
          />
        </Spin>
      </Container>
    </Drawer>
  )
}