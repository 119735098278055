import React, { useState, useCallback, useContext } from 'react';
import TituloTela from '../../../../components/TituloTela';
import { Card, DatePicker, Space, Button, Form, Tag, Col, Row } from 'antd';

import { ButtonContainer } from './styles';

import { FORMAT_DATE_BR } from '../../../../constants';

import moment from 'moment';
import 'moment/locale/pt-br';
import TabelaPersonalizada from '../../../../components/TabelaPersonalizada';

import api from '../../../../service/api';
import { Context } from '../../../../Context/AuthContext';
import { formatDecimal } from '../../../../utils/format-decimal';
import Loader from '../../../../components/Loader';

moment.locale('pt-br');
moment.updateLocale('pt-br', {
  weekdaysMin : ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"]
});

const coresClasse = {
  a: '#1cc88a',
  b: '#FCD34D',
  c: '#e74a3b',
}

export default function CurvaABCClientes() {
  const [loading, setLoading] = useState(false);
  const [dadosCurvaABC, setDadosCurvaABC] = useState([]);
  const { empresa } = useContext(Context);

  
  function onSubmit(value) {
    const {dataInicio, dataFim}= value;
    loadData(dataInicio, dataFim);
  }

  const loadData = useCallback( async (dataInicio, dataFim) => {
    setLoading(true);

    try {
      const { data } = await api.get(
        `/empresas/${empresa.id}/intercarg/faturamento/curva-abc-clientes`,
        {
          params: {
            dataInicio: moment(dataInicio).format('YYYY/MM/DD'),
            dataFim: moment(dataFim).format('YYYY/MM/DD'),
          }
        });
  
        console.log(data);
  
        const dataMapper = data.response.map((item) => ({
          key: item.id,
          id: item.id,
          nome: item.nome,
          vlr_total_liquido: formatDecimal(item.vlr_total_liquido),
          soma_geral: formatDecimal(item.soma_geral),
          perc_venda: formatDecimal(item.perc_venda),
          ordem: item.ordem,
          classe: item.classe,
        }))
  
        setDadosCurvaABC(dataMapper);
    } finally {
      setLoading(false);
    }
    }, [empresa.id])

  const columns = [      
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      align: 'right',
      isVisible: true,
      isSearch: true,
    },
    {
      title: 'Nome Cliente',
      dataIndex: 'nome',
      key: 'nome_cliente',
      width: 500,
      isVisible: true,
      isSearch: true,
    },    
    {
      title: 'Valor Total Vendas',
      dataIndex: 'vlr_total_liquido',
      key: 'vlr_total_liquido',
      width: 200,
      align: 'right',
      isVisible: true,
      isSearch: true,
    },
    {
      title: '% Vendas',
      dataIndex: 'perc_venda',
      key: 'perc_venda',
      width: 200,
      align: 'right',
      isVisible: true,
      isSearch: true,
    },
    {
      title: 'Classe',
      dataIndex: 'classe',
      key: 'classe',
      align: 'center',
      width: 70,
      isVisible: true,
      isSearch: true,
      render: value => (
        <Tag
          color={coresClasse[value.toLowerCase()]}
        >
          {value}
        </Tag>)
    },
  ];

  return (
    <>
      <TituloTela 
        codigoTela='02.76.1417'
        nomeTela='Curva ABC Clientes'
      />

      <Loader isLoading={loading}/>

      <Space 
        direction="vertical"
        style={{width: '100%'}}
        size="large"
      >

      <Form
        layout='vertical'
        colon={false}
        onFinish={onSubmit}
        initialValues={{
          dataInicio: moment(),
          dataFim: moment(),
        }}
      >
        <Card>
          <Row gutter={[12, 12]}>
            <Col xs={12} md={5} lg={4} xl={4}>
              <Form.Item
                name="dataInicio" 
                label="Data Inicio" 
                rules={[{ required: true, message: 'Campo obrigatório' },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const dataFim = getFieldValue('dataFim');
                            console.log({dataFim, value});
                            if (value > dataFim) {
                              return Promise.reject(new Error(`Data Inicio maior que data fim.`))
                            }
                            return Promise.resolve();
                          }
                        }),
                      ]}
              >
                <DatePicker
                  format={FORMAT_DATE_BR}
                  style={{width: '100%'}}
                />
              </Form.Item>
            </Col>

            <Col xs={12} md={5} lg={4} xl={4}>
              <Form.Item
                style={{gridColumn: 'span 1'}}
                name="dataFim" 
                label="Data Fim" 
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <DatePicker
                  format={FORMAT_DATE_BR}
                  style={{width: '100%'}}
                />
              </Form.Item>

            </Col>

            <Col xs={24} md={3} lg={2} xl={3}>
              <ButtonContainer>
                <Button
                    type='primary'
                    htmlType='submit'
                    style={{width: '100%'}}
                  >
                    Filtrar
                  </Button>
              </ButtonContainer>
            </Col>
          </Row>

          {/* <FormContainer>
            <Form.Item
              name="dataInicio" 
              label="Data Inicio" 
              rules={[{ required: true, message: 'Campo obrigatório' },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const dataFim = getFieldValue('dataFim');
                          console.log({dataFim, value});
                          if (value > dataFim) {
                            return Promise.reject(new Error(`Data Inicio maior que data fim.`))
                          }
                          return Promise.resolve();
                        }
                      }),
                    ]}
            >
              <DatePicker
                format={FORMAT_DATE_BR}
                style={{width: '100%'}}
              />
            </Form.Item>

            <Form.Item
              style={{gridColumn: 'span 1'}}
              name="dataFim" 
              label="Data Fim" 
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <DatePicker
                format={FORMAT_DATE_BR}
                style={{width: '100%'}}
              />
            </Form.Item>

            <ButtonContainer marginTop>
              <Button
                  type='primary'
                  htmlType='submit'
                  style={{width: '100%'}}
                >
                  Filtrar
                </Button>
            </ButtonContainer>
          </FormContainer> */}
        </Card>
      </Form>

      <TabelaPersonalizada 
        columns={columns}
        configTabela={{
          size: 'small',
          scroll: { x: 'auto' },
          pagination: {
            size: 'small',
            defaultPageSize: 20,
          }
        }}
        dataSource={dadosCurvaABC}
      />
        </Space>
    </>    
  )
} 