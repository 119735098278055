import React, { useState, useCallback, useEffect } from 'react';

import { 
  DatePicker,
  Space,
  Button,
  Form,
  Select
} from 'antd';

import TituloTela from '../../../../components/TituloTela';
import Loader from '../../../../components/Loader';
import DREVeiculoDetalhe from './DREVeiculoDetalhe';
import { DataTable } from '../../../../components/Table/DataTable';

import { Card, FormContainer, ButtonContainer } from './styles';

import api from '../../../../service/api';
import { useAuth } from '../../../../hooks/useAuth';
import { formatDecimal } from '../../../../utils/format-decimal';

import { columns } from './table-columns';


import moment from 'moment';
import 'moment/locale/pt-br';

moment.locale('pt-br');
moment.updateLocale('pt-br', {
  weekdaysMin : ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"]
});

const monthFormat = 'MM/YYYY';

const gestoresFrotaInitialState = [{ value: 0, label: 'Todos'}];

export default function DREVeiculos() {
  const [loading, setLoading] = useState(false);
  const [loadingGestoresFrota, setLoadingGestoresFrota] = useState(false);
  const [dreVeiculos, setDreVeiculos] = useState([]);
  const [gestoresFrota, setGestoresFrota] = useState(gestoresFrotaInitialState);
  const [veiculoSelecionado, setVeiculoSelecionado] = useState(null);
  const [periodo, setPeriodo] = useState({
    inicioPeriodo: moment().startOf('month'),
    finalPeriodo: moment(),
  })
  
  const { empresa } = useAuth();

  function onSubmit(value) {
    const {inicioPeriodo, finalPeriodo, idGestorForta} = value;

    loadData(inicioPeriodo, finalPeriodo, idGestorForta);
    
    setPeriodo({
      inicioPeriodo: moment(inicioPeriodo).startOf('month').format('YYYY/MM/DD'),
      finalPeriodo: moment(finalPeriodo).endOf('month').format('YYYY/MM/DD'),     
    })
  }

  const loadData = useCallback( async (dataInicio, dataFim, idGestoresFrota) => {
    setLoading(true);
    
    try {
      const params = {
        inicioPeriodo: moment(dataInicio).format('YYYY/MM/DD'),
        finalPeriodo: moment(dataFim).format('YYYY/MM/DD'),
        idGestor: idGestoresFrota
      }

      try {
        const { data } = await api.get(
          `/empresas/${empresa.id}/intercarg/financeiro/dre/veiculos`,
          {
            params
          }
        );

        const dreVeiculosMapper = data.response.map(
          veiculo => ({
            ...veiculo,
            valor_faturamento: Number(veiculo.valor_faturamento),
            valor_reentrega: Number(veiculo.valor_reentrega),
            valor_fat_geral: Number(veiculo.valor_fat_geral),
            perc_fat: Number(veiculo.perc_fat),
            despesas_custos: Number(veiculo.despesas_custos),
            resultado: Number(veiculo.resultado),
            valor_faturamento_formatado: formatDecimal(veiculo.valor_faturamento),
            valor_reentrega_formatado: formatDecimal(veiculo.valor_reentrega),
            valor_fat_geral_formatado: formatDecimal(veiculo.valor_fat_geral),
            perc_fat_formatado: formatDecimal(veiculo.perc_fat),
            despesas_custos_formatado: formatDecimal(veiculo.despesas_custos),
            resultado_formatada: formatDecimal(veiculo.resultado),
            media_km_litro: formatDecimal(veiculo.media_km_litro),
            media_km_litro_formatada: formatDecimal(veiculo.media_km_litro),
          })
        );

        setDreVeiculos(dreVeiculosMapper);

      } catch (error) {
        console.log(error);
      }
    } finally {
      setLoading(false);
    }
    }, [empresa.id]);

    const handleClickRow = useCallback((value) => {
      setVeiculoSelecionado(value)
    }, [])

    const tableConfig = {
      onClickRow: handleClickRow,
      columnPinning: {
        left: ['placa']
      } 
    }

    const closeDrawer = useCallback(() => {
      setVeiculoSelecionado(null)
    }, []);

    useEffect(() => {
      async function getAllGestoresFrota(){
        setLoadingGestoresFrota(true);

        try {
          const { data } = await api.get(`/empresas/${empresa.id}/logistica/gestor_frota`);

          const gestoresMapper = data.map(gestor => ({
            value: gestor.codigo,
            label: gestor.nome
          }));
          
          setGestoresFrota([
            ...gestoresFrotaInitialState,
            ...gestoresMapper
          ]);
        } catch (error) {
          
        } finally {
          setLoadingGestoresFrota(false);
        }
      }

      getAllGestoresFrota();
    }, [empresa.id])

  return (
    <>
      <DREVeiculoDetalhe
        veiculo={veiculoSelecionado}
        periodo={periodo}
        onCloseDrawer={closeDrawer}
      />

      <TituloTela 
        codigoTela='02.74.1429'
        nomeTela='DRE por Veículos'
      />

      <Loader isLoading={loading}/>

      <Space 
        direction="vertical"
        style={{width: '100%'}}
        size="large"
      >
        <Card>
          <Form
            layout='vertical'
            style={{width: '100%'}}
            colon={false}
            onFinish={onSubmit}
            initialValues={{
              inicioPeriodo: moment().startOf('month'),
              finalPeriodo: moment().endOf('month'),
              idGestorForta: 0,
            }}
          >
            <FormContainer>
              <Form.Item
                name="inicioPeriodo"
                label="Início Período" 
                rules={[{ required: true,
                          message: 'Campo obrigatório' },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const dataFim = getFieldValue('finalPeriodo');
                              
                              if (value > dataFim) {
                                return Promise.reject(new Error(`Data Inicio maior que data fim.`))
                              }
                              return Promise.resolve();
                            }
                          }),
                      ]}
              >
                <DatePicker
                  format={monthFormat}
                  style={{width: '100%'}}
                  picker="month"
                />
              </Form.Item>

              <Form.Item
                name="finalPeriodo" 
                label="Final Período" 
                rules={[
                  { 
                    required: true, 
                    message: 'Campo obrigatório',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const inicioPeriodo = getFieldValue('inicioPeriodo');
                      
                      if (value < inicioPeriodo) {
                        return Promise.reject(new Error(`Início Período menor que data início.`))
                      }
                      return Promise.resolve();
                    }
                  })
                ]}
              >
                <DatePicker
                  style={{width: '100%'}}
                  format={monthFormat}
                  picker="month"                    
                />
              </Form.Item>

              <Form.Item
                className='select-gestor'
                name="idGestorForta"
                label="Gestor"
              >
                <Select
                  loading={loadingGestoresFrota}
                  options={gestoresFrota}
                />
              </Form.Item>

              <ButtonContainer>
                <Button
                    type='primary'
                    htmlType='submit'
                    style={{width: '100%'}}
                  >
                    Filtrar
                  </Button>
              </ButtonContainer>              
            </FormContainer>
          </Form>
        </Card>

        <DataTable 
          columns={columns}
          dataSource={dreVeiculos}
          tableConfig={tableConfig}
        />
    </Space>
  </>    
)};