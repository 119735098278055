import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { Alert, Button, Col, Row, Select, Card as CardAntd, Statistic, Avatar, Typography, Space, Grid, ConfigProvider, notification, } from "antd";
import { SyncOutlined } from '@ant-design/icons';
import { BiDollar } from 'react-icons/bi';
import { GiWeight } from 'react-icons/gi';
import { IoMdPricetags } from "react-icons/io";

import { Column as ChartsColumn, Pie } from '@ant-design/charts'

import Loader from "../../../../components/Loader";
import TituloTela from "../../../../components/TituloTela";
import GrupoCards from "../../../../components/GrupoCards";

import api from '../../../../service/api';
import { Context } from "../../../../Context/AuthContext";

import { formatDecimal } from "../../../../utils/format-decimal";
import { round } from "../../../../utils/round";
import { MESES_EXTENSO } from "../../../../constants";

import { Container, ChartMensalContainer, ChartFaturamentoContainer } from './styles';

import { DataTableColumnHeader } from "../../../../components/Table/DataTableColumnHeader";
import { DataTable } from "../../../../components/Table/DataTable";
import ptBR from 'antd/lib/locale/pt_BR';
import Meta from "./components/Meta";
import FaturamentoDiario from "./components/FaturamentoDiario/index";
import { createColumnHelper } from "@tanstack/react-table";
import { AiOutlineFileSearch } from "react-icons/ai";
import { ButtonContainer } from "../../../../components/Table/DataTableColumnHeader/styles";
import styled from "styled-components";
import DetalheFaturamentoEmpresa from "./components/DetalhesFaturamentoEmpresa";

const { useBreakpoint } = Grid;

const erros = '';

const mesAtual = new Date().getMonth();
const anoAtual = new Date().getFullYear();

function TextSuffix({ children }) {
  return (
    <small style={{ fontSize: '16px', color: '#fff' }}>{children}</small>
  );
};

export const DetailButtonContainer = styled.button`
  display: flex;
  gap: 4px;
  align-items: center;
  background-color: transparent;
  border: none;
  white-space: nowrap;

  &:hover { 
    color: #1890ff;
    cursor: pointer;
  }
`

const columnHelper = createColumnHelper()

export default function Dasboard() {
  const [loading, setLoading] = useState(false);
  const [valorFaturamento, setValorFaturamento] = useState('0,00');
  const [pesoBruto, setPesoBruto] = useState('0,00');
  const [valorMedioTonelada, setValorMedioTonelada] = useState('0,00');
  const [meta, setMeta] = useState('0,00');
  const [percentualAtingidoMeta, setPercentualAtingidoMeta] = useState('0,00')
  const [faturamentoPorEmpresa, setFaturamentoPorEmpresa] = useState([]);
  const [faturamentoDiario, setFaturamentoDiario] = useState([]);
  const [faturamentoPorEmpresaChart, setFaturamentoPorEmpresaChart] = useState([]);
  const [faturamentoUltimo24MesesChart, setFaturamentoUltimo24MesesChart] = useState([]);
  const [cardFrota, setCardFrota] = useState({});
  const [cardTerceiros, setCardTerceitos] = useState({});
  const [opcoesAno, setOpcoesAno] = useState([anoAtual]);
  const [mesSelecionado, setMesSelecionado] = useState(mesAtual);
  const [anoSelecionado, setAnoSelecionado] = useState(anoAtual);

  const { empresa } = useContext(Context);

  const activeScreens = useBreakpoint();

  const configChartFaturamento = {
    data: faturamentoPorEmpresaChart,
    angleField: 'percentual',
    colorField: 'empresa',
    height: 400,
    label: {
      type: 'inner',
      offset: '-30%',
      formatter: ({ percentual }) => `${formatDecimal(percentual)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
  };

  const configChartFaturamento24 = {
    data: faturamentoUltimo24MesesChart,
    isGroup: true,
    xField: 'mes',
    yField: 'valor',
    seriesField: 'ano',
    label: null,
    legend: {
      position: 'right',
    },
    tooltip: {
      formatter: (date) => (
        { name: date.ano, value: formatDecimal(date.valor) })
    }
  };

  const loadDataDashboard = useCallback(async (periodo) => {
    try {
      setLoading(true);
      const { data } = await api.get(
        `/empresas/${empresa.id}/intercarg/faturamento/dashboard`, {
        params: { dataPeriodo: periodo },
      });

      const {
        totaisMes,
        dadosFaturamentoMes,
        faturamentoDiario,
        faturamentoUltimo24Meses,
        faturamentoFrota,
        faturamentoTerceiros
      } = data.response;

      const valorTotalFaturamentoEmpresas = totaisMes.faturamento
      const valorTotalPesoBrutoEmpresas = totaisMes.peso_bruto;

      const faturamentoEmpresas = dadosFaturamentoMes.map((empresa) => {
        const data = {
          id: empresa.id_empresa,
          nome_fantasia: empresa.nome_fantasia,
          valor: Number(empresa.valor_faturamento),
          meta: Number(empresa.meta),
          percentual: Number(round((100 * Number(empresa.valor_faturamento)) / valorTotalFaturamentoEmpresas)),
          perc_meta_concluido: Number(empresa.perc_meta_concluido),
          valor_medio_ton: Number(empresa.valor_medio_ton),
          peso_bruto_toneladas: Number(empresa.peso_bruto_toneladas),
          valor_formatado: formatDecimal(empresa.valor_faturamento),
          percentual_formatado: formatDecimal(round((100 * Number(empresa.valor_faturamento)) / valorTotalFaturamentoEmpresas)),
          peso_bruto_toneladas_formatado: formatDecimal(Number(empresa.peso_bruto_toneladas), 3),
          meta_formatada: formatDecimal(empresa.meta),
          perc_meta_concluido_formatado: formatDecimal(empresa.perc_meta_concluido),
          valor_medio_ton_formatado: formatDecimal(empresa.valor_medio_ton),
        }
        return data
      });

      const faturamentoEmpresasChart = dadosFaturamentoMes.map((empresa) => {
        const data = {
          empresa: empresa.nome_fantasia,
          percentual: round((100 * Number(empresa.valor_faturamento)) / valorTotalFaturamentoEmpresas)
        }
        return data
      });

      const faturamentoUltimo24MesesFormatado = faturamentoUltimo24Meses.map(item => (
        {
          periodo: item.periodo,
          mes: item.mes,
          ano: String(item.ano),
          valor: parseFloat(item.valor)
        }
      ));

      const faturamentoFrotaMapper = {
        valor_faturamento: formatDecimal(faturamentoFrota.valor_faturamento),
        perc_faturamento: formatDecimal(faturamentoFrota.perc_faturamento)
      }

      const faturamentoTerceirosMapper = {
        valor_faturamento: formatDecimal(faturamentoTerceiros.valor_faturamento),
        perc_faturamento: formatDecimal(faturamentoTerceiros.perc_faturamento),
        perc_lucro_bruto_terceiro: formatDecimal(faturamentoTerceiros.perc_lucro_bruto_terceiro),
      }


      setFaturamentoPorEmpresa(faturamentoEmpresas);
      setFaturamentoPorEmpresaChart(faturamentoEmpresasChart);
      setValorFaturamento(formatDecimal(valorTotalFaturamentoEmpresas));
      setFaturamentoDiario(faturamentoDiario)
      setMeta(totaisMes.meta)
      setPercentualAtingidoMeta(totaisMes?.porcentagem_realizada_meta || 0)
      setValorMedioTonelada(formatDecimal(totaisMes?.valor_medio_ton || 0))
      setPesoBruto(formatDecimal(valorTotalPesoBrutoEmpresas, 3));
      setFaturamentoUltimo24MesesChart(faturamentoUltimo24MesesFormatado)
      setCardFrota(faturamentoFrotaMapper);
      setCardTerceitos(faturamentoTerceirosMapper);
      setLoading(false);
    } catch (error) {
      console.error(error);
      notification.open({
        message: 'Erro!',
        description: "Ocorreu um erro ao buscar os dados :("
      });
      setLoading(false);
    }

  }, [empresa])

  useEffect(() => {
    try {
      async function getAnoInicio() {
        const { data } = await api.get(
          `/empresas/${empresa.id}/intercarg/faturamento/ano_primeiro_lancamento`
        );

        if (data.response.ano) {
          let anos = [];
          let anoPrimeiroLancamento = Number(data.response.ano);

          while (anoPrimeiroLancamento <= anoAtual) {
            anos.push(anoPrimeiroLancamento);
            anoPrimeiroLancamento++;
          }

          anos.sort(function (a, b) { return b - a });

          setOpcoesAno(anos);
        }
      }

      getAnoInicio();

    } catch (error) {
    }
  }, [empresa]);

  const columnsFaturamentoEmpresas = useMemo(() => [
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="#ID" />,
      accessorKey: "id",
      title: "#ID",
      widthColumn: 70,
      align: 'right',
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Nome" />,
      accessorKey: "nome_fantasia",
      title: "Nome",
      widthColumn: 120
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Valor (R$)" />,
      cell: ({ row }) => row.original.valor_formatado,
      accessorKey: "valor",
      title: "Valor (R$)",
      widthColumn: 120,
      align: 'right',
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Meta (R$)" />,
      cell: ({ row }) => row.original.meta_formatada,
      accessorKey: "meta",
      title: "Meta (R$)",
      widthColumn: 120,
      align: 'right',
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Perc. Conc. (%)" />,
      cell: ({ row }) => row.original.perc_meta_concluido_formatado,
      accessorKey: "perc_meta_concluido",
      title: "Perc. Conc. (%)",
      widthColumn: 100,
      align: 'right',
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Peso Bruto (t)" />,
      cell: ({ row }) => row.original.peso_bruto_toneladas_formatado,
      accessorKey: "peso_bruto_toneladas",
      title: "Peso Bruto (t)",
      widthColumn: 120,
      align: 'right',
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Valor Médio (R$/t)" />,
      cell: ({ row }) => row.original.valor_medio_ton_formatado,
      accessorKey: "valor_medio_ton",
      title: "Valor Médio (R$/t)",
      widthColumn: 120,
      align: 'right',
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="% Faturamento" />,
      cell: ({ row }) => row.original.percentual_formatado,
      accessorKey: "percentual",
      title: "% Faturamento",
      widthColumn: 120,
      align: 'right',
    },
  ], []);

  const [drawerConfigs, setDrawerConfigs] = useState({
    isOpen: false,
    empresa: {
      id: null,
      nome: null
    },
  })

  const openDetails = useCallback(
    ({ idEmpresa, nomeEmpresa, }) => {
      setDrawerConfigs({
        empresa: { id: idEmpresa, nome: nomeEmpresa },
        isOpen: true
      })
    },
    []
  )

  const closeDetails = useCallback(() => {
    setDrawerConfigs({
      isOpen: false,
      empresa: {
        id: null,
        nome: null
      },
    })
  }, [])

  const columnsFaturamentoEmpresasWithDetailsButton = [
    columnHelper.display({
      id: "openDrawer",
      widthColumn: 100,
      header: ({ header }) => (
        <ButtonContainer
          right={`${header.column.getAfter('right')}px`}
        >
          Ações
        </ButtonContainer>
      ),
      cell: ({ row }) => {
        return (
          <DetailButtonContainer
            onClick={() => {
              openDetails({
                idEmpresa: row.original.id,
                nomeEmpresa: row.original.nome_fantasia
              })
            }}
            type="button"
          >
            <AiOutlineFileSearch size={16} />
            Meta Diária
          </DetailButtonContainer>
        )
      }
    }),
    ...columnsFaturamentoEmpresas
  ]

  const configTableFaturamentoEmpresas = {
    initialColumnOrdering: [{ id: 'valor', desc: true }],
  }

  useEffect(() => {
    const mesFormated = mesSelecionado + 1 < 10 ? `0${mesSelecionado + 1}` : mesSelecionado + 1;
    const periodo = `${anoSelecionado}-${mesFormated}-01`
    loadDataDashboard(periodo);
  }, [empresa, mesSelecionado, anoSelecionado, loadDataDashboard]);

  function handleChangeMes(value) {
    setMesSelecionado(value);
  }

  function handleChangeAno(value) {
    setAnoSelecionado(value);
  }

  function handleAtualuzarDados() {
    const mesFormated = mesSelecionado + 1 < 10 ? `0${mesSelecionado + 1}` : mesSelecionado + 1;
    const periodo = `${anoSelecionado}-${mesFormated}-01`
    loadDataDashboard(periodo);
  }

  return (
    <Container>
      <Loader isLoading={loading} />

      <TituloTela codigoTela="02.76.1405" nomeTela="Dashboard Faturamento" />

      <Row>
        <Col>
          <Select
            defaultValue={`${MESES_EXTENSO[mesAtual]}`}
            style={{ width: 120, marginBottom: '18px', marginRight: '8px' }}
            onChange={handleChangeMes}
          >
            {MESES_EXTENSO.map((mes, index) => (
              <Select.Option
                key={mes}
                value={index}
              >
                {mes}
              </Select.Option>))}
          </Select>

          <Select
            defaultValue={`${anoAtual}`}
            style={{ width: 120, marginBottom: '18px', marginRight: '8px' }}
            onChange={handleChangeAno}
          >
            {opcoesAno.map((ano) => (
              <Select.Option
                key={ano}
                value={ano}
              >
                {ano}
              </Select.Option>
            ))}
          </Select>

          <Button
            type="primary"
            icon={<SyncOutlined />}
            onClick={handleAtualuzarDados}
          />
        </Col>

        <Col md={24} lg={24} xs={24} sm={24} xl={24}>
          {erros &&

            <Alert
              closeText="Fechar"
              message={erros}
              showIcon
              type="error"
              action={
                <Button size="small" danger show={true}>
                  Detalhes
                </Button>
              }
            />
          }
        </Col>
      </Row>

      <Row gutter={[12, 16]}>
        <Col xs={24} md={12} lg={12} xl={12} xxl={6}>
          <CardAntd
            style={{ background: '#34D399', borderRadius: 8 }}
          >
            <Space direction="horizontal" size="large" >
              <Avatar
                shape="square"
                style={{ display: "flex", alignItems: 'center', justifyContent: 'center', background: '#fff' }}
                size={64}
                icon={<BiDollar size={36} color='#34D399' />}
              />

              <CardAntd.Meta
                title={
                  <Typography.Title level={4}
                    style={{ color: '#fff', letterSpacing: 0.5 }}
                  >
                    Faturamento
                  </Typography.Title >
                }
                description={
                  <Typography.Text
                    style={{ color: '#fff', fontSize: 24 }}
                  >
                    R$ {valorFaturamento}
                  </Typography.Text>
                }
              />
            </Space>
          </CardAntd>
        </Col>
        <Col xs={24} md={12} lg={12} xl={12} xxl={6}>
          <Meta meta={meta} perc={percentualAtingidoMeta} />
        </Col>

        <Col xs={24} md={12} lg={12} xl={12} xxl={6}>
          <CardAntd
            style={{ background: '#60A5FA', borderRadius: 8 }}
          >
            <Space direction="horizontal" size="large" >
              <Avatar
                shape="square"
                style={{ display: "flex", alignItems: 'center', justifyContent: 'center', background: '#fff' }}
                size={64}
                icon={<GiWeight size={36} color='#60A5FA' />}
              />

              <CardAntd.Meta
                title={
                  <Typography.Title level={4}
                    style={{ color: '#fff', letterSpacing: 0.5 }}
                  >
                    Peso Bruto Tonelada
                  </Typography.Title >
                }
                description={
                  <Typography.Text
                    style={{ color: '#fff', fontSize: 24 }}
                  >
                    {pesoBruto} t
                  </Typography.Text>
                }
              />
            </Space>
          </CardAntd>
        </Col>
        <Col xs={24} md={12} lg={12} xl={12} xxl={6}>
          <CardAntd
            style={{ background: '#60A5FA', borderRadius: 8 }}
          >
            <Space direction="horizontal" size="large" >
              <Avatar
                shape="square"
                style={{ display: "flex", alignItems: 'center', justifyContent: 'center', background: '#fff' }}
                size={64}
                icon={<IoMdPricetags size={36} color='#60A5FA' />}
              />

              <CardAntd.Meta
                title={
                  <Typography.Title level={4}
                    style={{ color: '#fff', letterSpacing: 0.5 }}
                  >
                    Valor médio tonelada
                  </Typography.Title >
                }
                description={
                  <Typography.Text
                    style={{ color: '#fff', fontSize: 24 }}
                  >
                    R$ {valorMedioTonelada}
                  </Typography.Text>
                }
              />
            </Space>
          </CardAntd>
        </Col>
        <Col xs={24} md={12} lg={12} xl={12} xxl={12}>
          <CardAntd style={{ background: '#34D399', borderRadius: 8 }}>
            <Typography.Title level={5} style={{ color: '#FFFF', letterSpacing: 0.5 }}>Faturamento Frota</Typography.Title>

            <Row gutter={[0, 16]}>
              <Col xs={13} md={13} lg={13} xl={13} xxl={13}>
                <Statistic
                  title={<Typography.Text style={{ color: '#FFFF' }}>Valor</Typography.Text>}
                  value={cardFrota.valor_faturamento}
                  valueStyle={{ color: '#fff' }}
                  prefix={<TextSuffix>R$</TextSuffix>}
                />
              </Col>

              <Col xs={1} md={1} lg={1} xl={1} xxl={1}>
                <div style={{ display: 'flex', justifyContent: 'center', height: '90%' }}>
                  <div style={{ width: '1px', height: '100%', backgroundColor: '#FFFFFF' }} />
                </div>
              </Col>

              <Col xs={10} md={10} lg={10} xl={10} xxl={10}>
                <Statistic
                  title={<Typography.Text style={{ color: '#FFFF' }}>Perc. Faturamento</Typography.Text>}
                  value={cardFrota.perc_faturamento}
                  valueStyle={{ color: '#fff' }}
                  suffix={<TextSuffix>%</TextSuffix>}
                />
              </Col>
            </Row>
          </CardAntd>
        </Col>

        <Col xs={24} md={12} lg={12} xl={12} xxl={12}>
          <CardAntd style={{ background: '#34D399', borderRadius: 8 }}>
            <Typography.Title level={5} style={{ color: '#FFFFFF', letterSpacing: 0.5 }}>Faturamento Terceiros</Typography.Title>

            <Row gutter={[0, 16]}>
              <Col xs={13} md={13} lg={13} xl={10} xxl={10}>
                <Statistic
                  title={<Typography.Text style={{ color: '#FFFFFF' }}>Valor</Typography.Text>}
                  value={cardTerceiros.valor_faturamento}
                  valueStyle={{ color: '#FFFFFF' }}
                  prefix={<TextSuffix>R$</TextSuffix>}
                />
              </Col>

              <Col xs={1} md={1} lg={1} xl={1} xxl={1}>
                <div style={{ display: 'flex', justifyContent: 'center', height: '90%' }}>
                  <div style={{ width: '1px', height: '100%', backgroundColor: '#FFFFFF' }} />
                </div>
              </Col>

              <Col xs={10} md={10} lg={10} xl={6} xxl={6}>
                <Statistic
                  title={<Typography.Text style={{ color: '#FFFFFF' }}>Perc. Faturamento</Typography.Text>}
                  value={cardTerceiros.perc_faturamento}
                  valueStyle={{ color: '#FFFFFF' }}
                  suffix={<TextSuffix>%</TextSuffix>}
                />
              </Col>

              {activeScreens.xl && (
                <Col xs={1} md={1} lg={1} xl={1} xxl={1}>
                  <div style={{ display: 'flex', justifyContent: 'center', height: '90%' }}>
                    <div style={{ width: '1px', height: '100%', backgroundColor: '#FFFFFF' }} />
                  </div>
                </Col>
              )}

              <Col xs={11} md={11} lg={11} xl={6} xxl={6}>
                <Statistic
                  title={<Typography.Text style={{ color: '#FFFFFF' }}>Perc. Lucro Bruto</Typography.Text>}
                  value={cardTerceiros.perc_lucro_bruto_terceiro}
                  valueStyle={{ color: '#FFFFFF' }}
                  suffix={<TextSuffix >%</TextSuffix>}
                />
              </Col>
            </Row>
          </CardAntd>
        </Col>
      </Row>

      <Row gutter={[12, 16]}>
        <Col xl={24}>
          <GrupoCards titulo='Faturamento por Filial'>
            <Row gutter={24}>
              <Col md={24} xl={24}>
                <DataTable
                  columns={columnsFaturamentoEmpresas}
                  dataSource={faturamentoPorEmpresa}
                  tableConfig={configTableFaturamentoEmpresas}
                />
              </Col>

            </Row>
          </GrupoCards>
        </Col>
      </Row>

      {faturamentoDiario && faturamentoDiario.length !== 0 &&
        <FaturamentoDiario faturamentoDiario={faturamentoDiario} />
      }

      <Row gutter={[12, 16]}>
        <Col md={24} lg={24} xs={24} sm={24} xl={24}>
          <GrupoCards titulo='Faturamento por Filial'>
            <Row gutter={24}>
              <Col md={24} xl={24}>
                <DataTable
                  columns={columnsFaturamentoEmpresasWithDetailsButton}
                  dataSource={faturamentoPorEmpresa}
                  tableConfig={configTableFaturamentoEmpresas}
                />
                {drawerConfigs.isOpen && (
                  <DetalheFaturamentoEmpresa
                    isOpen={drawerConfigs.isOpen}
                    periodo={`${anoSelecionado}-${mesSelecionado + 1 < 10 ? `0${mesSelecionado + 1}` : mesSelecionado + 1}-01`}
                    empresaFilial={drawerConfigs.empresa}
                    closeFn={closeDetails}
                  />
                )}
              </Col>

            </Row>
          </GrupoCards>
        </Col>
      </Row>

      <Row gutter={[12, 16]}>
        <Col md={24} lg={24} xs={24} sm={24} xl={12}>
          <GrupoCards titulo='Porcentagem no faturamento por filial'>
            <ChartFaturamentoContainer>
              <Pie {...configChartFaturamento} />
            </ChartFaturamentoContainer>
          </GrupoCards>
        </Col>
        <Col md={24} lg={24} xs={24} sm={24} xl={12}>
          <GrupoCards titulo='Faturamento mensal últimos 24 meses'>
            <ChartMensalContainer>
              <ChartsColumn {...configChartFaturamento24} />
            </ChartMensalContainer>
          </GrupoCards>
        </Col>
      </Row>
    </Container>
  )
}