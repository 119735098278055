import React, { useCallback, useMemo, useState } from "react"
import { createColumnHelper, flexRender, getCoreRowModel, getExpandedRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { BodyRow, Container, DetailButtonContainer, Table, Td, Th } from "./styles";

import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { ButtonContainer } from './DataTableColumnHeader/styles';
import { DataTable } from "../../../../components/Table/DataTable";
import { Empty } from "antd";
import moment from 'moment';
import { AiOutlineFileSearch } from "react-icons/ai";
import { DetalhesApontamento } from "./DetalhesApontamento";

const defaultValueTableConfig = {
  initialColumnOrdering: [],
  onClickRow: null,
  convertTableIntoCardMobileVersion: true
}

const columnHelper = createColumnHelper();

const ExpandableTable = ({
  columns = [],
  dataSource = [],
  detailsColumns = [],
  tableConfig: tableConfigValues,
  timePeriod = {
    from: moment(new Date(), 'YYYY/MM/DD').startOf('month'),
    to: moment(new Date(), 'YYYY/MM/DD'),
  }
}) => {

  const tableConfig = {
    ...defaultValueTableConfig
  }


  const [sorting, setSorting] = useState(tableConfig.initialColumnOrdering);
  const [expanded, setExpanded] = useState({});
  const [drawerConfigs, setDrawerConfigs] = useState({
    isOpen: false,
    operador: {
      id: null,
      nome: null
    },
    produtoAcabado: {
      id: null,
      nome: null
    },
  })

  const openApointmentDetails = useCallback(
    ({ idOperador, nomeOperador, idProdutoAcabado, nomeProdutoAcabado }) => {
      setDrawerConfigs({
        operador: { id: idOperador, nome: nomeOperador },
        produtoAcabado: { id: idProdutoAcabado, nome: nomeProdutoAcabado },
        isOpen: true
      })
    },
    []
  )

  const closeApointmentDetails = useCallback(() => {
    setDrawerConfigs({
      isOpen: false,
      operador: {
        id: null,
        nome: null
      },
      idProdutoAcabado: {
        id: null,
        nome: null
      },
    })
  }, [])

  const detailColumnsWithButton = useMemo(() => ([
    columnHelper.display({
      id: "openDrawer",
      widthColumn: 60,
      header: ({ header }) => (
        <ButtonContainer
          right={`${header.column.getAfter('right')}px`}
        >
          Ações
        </ButtonContainer>
      ),
      cell: ({ row }) => {
        return (
          <DetailButtonContainer
            onClick={() => {
              openApointmentDetails({
                idOperador: row.original.id_operador,
                nomeOperador: row.original.nome_operador,
                idProdutoAcabado: row.original.id_produto_acabado,
                nomeProdutoAcabado: row.original.desc_produto_acabado
              })
            }}
            type="button"
          >
            <AiOutlineFileSearch size={16} />
            Detalhes
          </DetailButtonContainer>
        )
      }
    }),
    ...detailsColumns
  ]), [detailsColumns, openApointmentDetails])

  const columnsExpanded = [
    columnHelper.display({
      id: "expander",
      widthColumn: 40,
      header: ({ table }) => (
        <ButtonContainer
          justifyContent='center'
          {...{
            onClick: table.getToggleAllRowsExpandedHandler()
          }}
        >
          {table.getIsAllRowsExpanded() ? <MdExpandLess size={16} /> : <MdExpandMore size={16} />}
        </ButtonContainer>
      ),
      cell: ({ row }) => (
        row.original.detalhes.length > 0 &&
        <ButtonContainer
          justifyContent='center'
          {...{
            onClick: () => row.toggleExpanded(),
            style: { cursor: "pointer" }
          }}
        >
          {row.getIsExpanded() ? <MdExpandLess size={16} /> : <MdExpandMore size={16} />}
        </ButtonContainer>
      )
    }),
    ...columns
  ];

  const table = useReactTable({
    columns: columnsExpanded,
    data: dataSource,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    state: {
      sorting,
      expanded
    },
  });

  return (
    <Container>
      <DetalhesApontamento
        closeFn={closeApointmentDetails}
        operador={drawerConfigs.operador}
        produtoAcabado={drawerConfigs.produtoAcabado}
        isOpen={drawerConfigs.isOpen}
        timePeriod={timePeriod}
      />

      <Table
        convertTableIntoCardMobileVersion={tableConfig.convertTableIntoCardMobileVersion}
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, _index) => {
                return (
                  <Th
                    key={header.id}
                    width={header.column.columnDef.widthColumn}
                    align={header.column.columnDef.align}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </Th>
                );
              })}
            </tr>
          ))}
        </thead>

        <tbody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => {
              return (
                <React.Fragment key={row.id}>
                  <BodyRow
                    key={row.id}
                    className={`backgroundColorDark ${row.getIsExpanded() ? 'hideBorder' : ''}`}
                    onClick={tableConfig.onClickRow ? () => tableConfig.onClickRow(row) : null}
                    cursorPointer={tableConfig.onClickRow ? true : null}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <Td
                        key={cell.id}
                        data-label={cell.column.columnDef.title}
                        align={cell.column.columnDef.align}
                        width={cell.column.columnDef.widthColumn}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    ))}
                  </BodyRow>

                  {
                    row.getIsExpanded() ? (
                      <BodyRow
                        key={`${146}`}
                        className='disableBackgroundColor'
                      >
                        <Td
                          colSpan={columns.length + 1}
                        >
                          <DataTable
                            columns={detailColumnsWithButton}
                            dataSource={row.original.detalhes || []}
                          />
                        </Td>
                      </BodyRow>
                    ) : null}

                </React.Fragment>
              )
            })
          ) : (
            <BodyRow>
              <Td colSpan={columns.length}>
                <Empty />
              </Td>
            </BodyRow>
          )}
        </tbody>
      </Table>
    </Container>
  )
}

export default ExpandableTable