import React, { useState, useCallback, useContext, useMemo } from 'react';

import { 
  Card,
  DatePicker,
  Space,
  Button,
  Form,
  Tag,
  Col,
  Row,
  Drawer, 
  Empty,
  Collapse,
} from 'antd';

import TituloTela from '../../../components/TituloTela';
import Loader from '../../../components/Loader';
import MediaMotoristaDetalhes from './MediaMotoristaDetalhes';
import MediaVeiculoDetalhes from './MediaVeiculoDetalhes';

import { DataTable } from '../../../components/Table/DataTable';
import { DataTableColumnHeader } from '../../../components/Table/DataTableColumnHeader';

import { ButtonContainer, TituloCollapse } from './styles';

import { FORMAT_DATE_BR } from '../../../constants';

import moment from 'moment';
import 'moment/locale/pt-br';

import api from '../../../service/api';
import { Context } from '../../../Context/AuthContext';
import { formatDecimal } from '../../../utils/format-decimal';

moment.locale('pt-br');
moment.updateLocale('pt-br', {
  weekdaysMin : ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"]
});

export default function MediaCombustiveis() {
  const [loading, setLoading] = useState(false);
  const [mediaMotoristas, setMediaMotoristas] = useState([]);
  const [mediaVeiculos, setMediaVeiculos] = useState([]);
  const [itemSelecionado, setItemSelecionado] = useState(null);
  const [periodo, setPeriodo] = useState({
    dataInicio: moment().startOf('month'),
    dataFim: moment().format('YYYY/MM/DD'),  
  });
  
  const { empresa } = useContext(Context);

  const openDrawer = itemSelecionado ? true : false;
  const hasMediaMotoristas = mediaMotoristas.length > 0;
  const hasMediaVeiculos = mediaVeiculos.length > 0;

  function onSubmit(value) {
    const {dataInicio, dataFim}= value;
    
    loadData(dataInicio, dataFim);

    setPeriodo({
      dataInicio: moment(dataInicio).format('YYYY/MM/DD'),
      dataFim: moment(dataFim).format('YYYY/MM/DD'),
    });
  }

  const loadData = useCallback( async (dataInicio, dataFim) => {
    setLoading(true);
    
    try {
      const params = {
        dataInicio: moment(dataInicio).format('YYYY/MM/DD'),
        dataFim: moment(dataFim).format('YYYY/MM/DD'),
      }

      const getMediaMotoristas = api.get(
        `/empresas/${empresa.id}/frota/combustiveis/mediaMotoritas`,
        {
          params
        }
      );

      const getMediaVeiculos = api.get(
        `/empresas/${empresa.id}/frota/combustiveis/mediaVeiculos`,
        {
          params
        }
      );
        
      const [mediaMotoristas, mediaVeiculos] = await Promise.all([
        getMediaMotoristas,
        getMediaVeiculos
      ]);

      const mediaMotoristasMapper = mediaMotoristas.data.response.map(
        media => ({
          ...media,
          media_diesel: Number(media.media_diesel),
          media_arla: Number(media.media_arla),
          media_diesel_esperada: Number(media.media_diesel_esperada), 
          perc_media_diesel_alcancado: Number(media.perc_media_diesel_alcancado), 
          media_diesel_formatada: formatDecimal(media.media_diesel, 3),
          media_arla_formatada: formatDecimal(media.media_arla, 3),
          media_diesel_esperada_formatada: formatDecimal(media.media_diesel_esperada, 3), 
          perc_media_diesel_alcancado_formatada: formatDecimal(media.perc_media_diesel_alcancado), 
        })
      );
      
      const mediaVeiculosMapper = mediaVeiculos.data.response.map(
        media => ({
          ...media,
          media_diesel: Number(media.media_diesel),
          media_arla: Number(media.media_arla),
          media_diesel_esperada: Number(media.media_diesel_esperada),
          perc_media_diesel_alcancado: Number(media.perc_media_diesel_alcancado),
          media_diesel_formatada: formatDecimal(media.media_diesel, 3),
          media_arla_formatada: formatDecimal(media.media_arla, 3),
          media_diesel_esperada_formatada: formatDecimal(media.media_diesel_esperada, 3),
          perc_media_diesel_alcancado_formatada: formatDecimal(media.perc_media_diesel_alcancado)
        })
      );      
      
      setMediaMotoristas(mediaMotoristasMapper);
      setMediaVeiculos(mediaVeiculosMapper);

    } finally {
      setLoading(false);
    }
    }, [empresa.id])

  const columnsMediaMotoristas = useMemo(() => [
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="#ID" />,
      title: "ID",
      accessorKey: "id_motorista",
      widthColumn: 100,
      align: 'right',
      cell: ({ row }) => (
        <Tag 
          style={{margin: 0, fontSize: 14}}
          color="#108ee9"
        >
          {row.getValue("id_motorista")}
        </Tag>),
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Nome" />,
      title: "Nome",
      accessorKey: "nome_motorista",
      widthColumn: 300,
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Placa" />,
      title: "Placa",
      accessorKey: "placa",
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Marca" />,
      title: "Marca",
      accessorKey: "marca",
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Modelo" />,
      title: "Modelo",
      accessorKey: "modelo",
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Rodado" />,
      title: "Rodado",
      accessorKey: "descricao_rodado",
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Média Arla" />,
      cell: ({ row }) => row.original.media_arla_formatada,
      title: "Média Arla",
      accessorKey: "media_arla",
      widthColumn: 100,
      align: 'right',
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Média Diesel" />,
      cell: ({ row }) => row.original.media_diesel_formatada,
      title: "Média Diesel",
      accessorKey: "media_diesel",
      widthColumn: 100,
      align: 'right',
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Média Diesel Esperada" />,
      cell: ({ row }) => row.original.media_diesel_esperada_formatada,
      title: "Média Diesel Esperada",
      accessorKey: "media_diesel_esperada",
      widthColumn: 100,
      align: 'right',
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Média Diesel Alcançada" />,
      cell: ({ row }) => {
        const color = row.getValue('perc_media_diesel_alcancado') < 100 ? '#f5222d' : '#389e0d';

        return (
          <span style={{color: `${color}`}}> 
            { row.original.perc_media_diesel_alcancado_formatada }
          </span>
        );
       },
      title: "Média Diesel Esperada",
      accessorKey: "perc_media_diesel_alcancado",
      widthColumn: 100,
      align: 'right',
    } 
  ], []);

  const columnsMediaVeiculos = useMemo(() => [
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Placa" />,
      title: "Placa",
      accessorKey: "placa",
      widthColumn: 100,
      cell: ({ row }) => (
        <Tag 
          style={{margin: 0, fontSize: 14}}
          color="#108ee9"
        >
          {row.getValue("placa")}
        </Tag>
      ),
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Marca" />,
      title: "Marca",
      accessorKey: "marca",
      widthColumn: 200,
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Modelo" />,
      title: "Modelo",
      accessorKey: "modelo",
      widthColumn: 250,
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Ano" />,
      title: "Ano",
      accessorKey: "ano",
      widthColumn: 100,
      align: 'right',
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Rodado" />,
      title: "Rodado",
      accessorKey: "descricao_rodado",
      widthColumn: 150,
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Id Mot." />,
      title: "Id Mot.",
      accessorKey: "id_motorista",
      widthColumn: 100,
      align: 'right',
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Nome Mot." />,
      title: "Nome Mot.",
      accessorKey: "nome_motorista",
      widthColumn: 300,
    },

    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Média Arla" />,
      cell: ({ row }) => row.original.media_arla_formatada,
      title: "Média Arla",
      accessorKey: "media_arla",
      widthColumn: 100,
      align: 'right',
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Média Diesel" />,
      cell: ({ row }) => row.original.media_diesel_formatada,
      title: "Média Diesel ",
      accessorKey: "media_diesel",
      widthColumn: 100,
      align: 'right',
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Média Diesel Esperada" />,
      cell: ({ row }) => row.original.media_diesel_esperada_formatada,
      title: "Média Diesel Esperada",
      accessorKey: "media_diesel_esperada",
      widthColumn: 100,
      align: 'right',
    },
    {
      header: ({ column }) => <DataTableColumnHeader column={column} title="Média Diesel Alcançada" />,
      cell: ({ row }) => {
        const color = row.getValue('perc_media_diesel_alcancado') < 100 ? '#f5222d' : '#389e0d';

        return (
          <span style={{color: `${color}`}}> 
            { row.original.perc_media_diesel_alcancado_formatada }
          </span>
        )
      },
      title: "Média Diesel Esperada",
      accessorKey: "perc_media_diesel_alcancado",
      widthColumn: 100,
      align: 'right',
    }
  ], []);

  const onClickRowTableMotoristas = useCallback((value) => {
    const data = {
      tipo: 'motorista',
      data: value
    }
    setItemSelecionado(data) 
  }, [])

  const onClickRowTableVeiculos = useCallback((value) => {
    const data = {
      tipo: 'veiculo',
      data: value
    };

    setItemSelecionado(data);
  }, []);

  const configTableMediaMotoristas = useMemo(() =>({
    onClickRow: onClickRowTableMotoristas,
    columnPinning: {
      left: ['id_motorista']
    } 
  }), [onClickRowTableMotoristas]);

  const configTableMediaVeiculos = useMemo(() =>({
    onClickRow: onClickRowTableVeiculos,
    columnPinning: {
      left: ['placa']
    } 
  }), [onClickRowTableVeiculos]);

  function handleCloseDrawer() {
    setItemSelecionado(null);
  }

  return (
    <>
      <TituloTela 
        codigoTela='02.78.1418'
        nomeTela='Média de Combustíveis'
      />

      <Loader isLoading={loading}/>

      <Drawer
        title={<TituloTela 
                  codigoTela='02.78.1419'
                  nomeTela='Detalhes Média de Combustíveis'
               />} 
        width="90%" 
        placement="right"
        onClose={() => handleCloseDrawer()}
        visible={openDrawer}
      >
        {itemSelecionado?.tipo === 'motorista' && (
          <MediaMotoristaDetalhes
            onCloseDrawer={handleCloseDrawer} 
            motorista={itemSelecionado.data}
            periodo={periodo}
          />
        )}

        {itemSelecionado?.tipo === 'veiculo' && (
          <MediaVeiculoDetalhes
            onCloseDrawer={handleCloseDrawer} 
            veiculo={itemSelecionado.data}
            periodo={periodo}
          />
        )}
      </Drawer>

      <Space 
        direction="vertical"
        style={{width: '100%'}}
        size="large"
      >

      <Form
        layout='vertical'
        colon={false}
        onFinish={onSubmit}
        initialValues={{
          dataInicio: moment().startOf('month'),
          dataFim: moment(),
        }}
      >
        <Card>
          <Row gutter={[12, 12]}>
            <Col xs={12} md={5} lg={4} xl={4}>
              <Form.Item
                name="dataInicio" 
                label="Data Inicio" 
                rules={[{ required: true, message: 'Campo obrigatório' },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const dataFim = getFieldValue('dataFim');
                            
                            if (value > dataFim) {
                              return Promise.reject(new Error(`Data Inicio maior que data fim.`))
                            }
                            return Promise.resolve();
                          }
                        }),
                      ]}
              >
                <DatePicker
                  format={FORMAT_DATE_BR}
                  style={{width: '100%'}}
                />
              </Form.Item>
            </Col>

            <Col xs={12} md={5} lg={4} xl={4}>
              <Form.Item
                name="dataFim" 
                label="Data Fim" 
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <DatePicker
                  format={FORMAT_DATE_BR}
                  style={{width: '100%'}}
                />
              </Form.Item>

            </Col>

            <Col xs={24} md={3} lg={2} xl={3}>
              <ButtonContainer>
                <Button
                    type='primary'
                    htmlType='submit'
                    style={{width: '100%'}}
                  >
                    Filtrar
                  </Button>
              </ButtonContainer>
            </Col>
          </Row>
        </Card>
      </Form>

      <Collapse>
        <Collapse.Panel 
          header={<TituloCollapse>Médias por Veículos/Motoristas</TituloCollapse>}
        >
          {!hasMediaMotoristas && <Empty />}

          {hasMediaMotoristas && 
            <DataTable 
              columns={columnsMediaVeiculos}
              dataSource={mediaVeiculos}
              tableConfig={configTableMediaVeiculos}
            />
          }
        </Collapse.Panel>
      </Collapse>

      <Collapse>
        <Collapse.Panel
          header={<TituloCollapse>Médias por Motoristas/Placas</TituloCollapse>}
        >
          {!hasMediaVeiculos && <Empty />}

          {hasMediaVeiculos &&         
            <DataTable 
              columns={columnsMediaMotoristas}
              dataSource={mediaMotoristas}
              tableConfig={configTableMediaMotoristas}
            />
          }
        </Collapse.Panel>
      </Collapse>
    </Space>
  </>    
)};